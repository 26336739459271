export class Network {
  code: number;
  name: string;

  constructor(code: number, name: string) {
    this.code = code;
    this.name = name;
  }
}

export interface network_i {
  code: number;
  name: string;
}
