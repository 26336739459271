// import classes
import { Station } from '@/models/context/station';
import { DataLogger } from '@/models/context/data_logger';
import { Network } from '@/models/context/network';
import { Observation } from '@/models/context/observation';
import { ObservationSorter } from '@/models/observation_sorter/observation_sorter';
import { Granularity } from '@/models/context/granularity';
import { Unit } from '@/models/context/unit';
import { User, UserDepth } from '@/models/auth/user';
import { Notification } from '@/models/utils/notification';
import { Dashboard } from '@/models/dashboard/dashboard';
import { Page } from '@/models/utils/page';
import { LogCommunication } from '@/models/log/log_communication';

export interface store_i {
  // context
  has_context: boolean;
  stations: Array<Station>;
  data_loggers: Array<DataLogger>;
  networks: Array<Network>;
  observations: Array<Observation>;
  observation_sorters: Array<ObservationSorter>;
  granularities: Array<Granularity>;
  units: Array<Unit>;

  // authentification
  user: User;
  session_timeout: boolean;

  // web socket
  web_socket: WebSocket | null;
  web_socket_open: boolean;
  last_stations_communication: Record<number, LogCommunication>;
  notifications: Array<Notification>;

  // dashboard
  widgets_id_counter: number;
  dashboard_change: boolean;
  dashboard: Dashboard;

  // other
  timezone: string;
  graphs_colors: Array<string>;
  pages: Array<Page>;

  snackbar: Record<string, unknown>;
}

export function reset_default_pages(): Array<Page> {
  return [
    { name: 'Dashboard', icon: 'dashboard' },
    { name: 'Graphs', icon: 'show_chart' },
    { name: 'Table', icon: 'table_rows' },
    { name: 'Maintenance', icon: 'engineering' },
  ];
}

export function reset_default_store(): store_i {
  return {
    has_context: false,
    stations: [],
    data_loggers: [],
    networks: [],
    observations: [],
    observation_sorters: [],
    granularities: [],
    units: [],
    user: new UserDepth(0, 0, '', '', 0, false, '', '', {}, [], [], false, {}),
    session_timeout: false,

    web_socket: null,
    web_socket_open: false,
    last_stations_communication: {},
    notifications: [],

    widgets_id_counter: 0,
    dashboard_change: false,
    dashboard: new Dashboard(0, '', []),

    timezone: '',
    graphs_colors: [],
    pages: reset_default_pages(),

    snackbar: {},
  };
}
