// import classes
import { DateRange } from '@/models/data_context/date_range';

// import interfaces
import moment from 'moment-timezone';

export class DataMultipleGraphWidget {
  data: Array<Array<Date | string | number | undefined>>;
  date_range: DateRange;

  constructor(
    timezone = 'UTC',
    data: Array<Array<string | number | null | undefined | string>> = [],
    date_range: { from: string; to: string; } = {
      from: moment.tz(timezone).toISOString(),
      to: moment.tz(timezone).toISOString(),
    },
  ) {
    this.date_range = new DateRange(
      moment.tz(date_range.from, timezone),
      moment.tz(date_range.to, timezone)
    );

    if (data.length > 0 && data[0].length > 0 && data[0].includes(-1000000000))
      for (const i in data[0])
        if (data[0][i] === -1000000000)
          data[0][i] = NaN;

    for (const line of data)
      for (const i in line)
        if (line[i] === null)
          line[i] = undefined;

    this.data = [];
    for (const line of data)
      this.data.push([new Date(line[0] as string), ...line.slice(1) as Array<string | number | undefined>]);
  }
}
